<template>
  <div>
    <head-bar :title="title">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../assets/arrow-left.svg" alt="" aria-hidden="true" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="flashback-layout">
      <div class="flashback-items" v-if="flashbackMediaItems">
        <div
          v-for="mediaItem in flashbackMediaItems"
          :key="mediaItem.id"
          class="flashback-item"
        >
          <img
            class="flashback-item__image"
            :src="mediaItem.default_url"
            :alt="mediaItem.title"
            loading="lazy"
          />

          <div class="flashback-item__title" v-if="mediaItem.title">
            {{ mediaItem.title }}
          </div>
        </div>
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import HeadBar from '../components/HeadBar.vue';
import HeadBarLink from '../components/HeadBarLink.vue';
import AppLayout from './AppLayout.vue';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    AppLayout,
    HeadBarLink,
    HeadBar,
  },

  mounted() {
    if (!this.user || (this.user && !this.user.flashback_media_items.length)) {
      this.$router.go(-1);
    }
  },

  computed: {
    ...mapGetters([
      'user',
    ]),

    title() {
      if (!this.user) {
        return 'Terugblik';
      }

      return `Terugblik ${this.user.flashback_date}`;
    },

    flashbackMediaItems() {
      if (!this.user) {
        return [];
      }

      return this.user.flashback_media_items ? this.user.flashback_media_items : [];
    },
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.flashback-layout {
  padding-top: 3.5rem;
  padding-bottom: 4.5rem;
}

.flashback-items {
  margin: 0 -.25rem;
  padding: 0 .5rem;
  display: flex;
  flex-wrap: wrap;
}

.flashback-item {
  padding: 0 .25rem;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: .75rem;
}

.flashback-item__image {
  width: 100%;
  display: block;
  max-width: 100%;
  border-radius: .625rem;
}

.flashback-item__title {
  font-size: .875rem;
  margin-top: .25rem;
  line-height: 1;
}
</style>
